import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const OgrodySwiatelPP = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Piotruś Pan",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/piotrus_pan_og_image.png"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #E8C575, #D1AF5E, #BF9942, #BF9B4B, #D9B766 )",
        icons: "#E8C575",
        navClass: "piotrus_pan__nav",
        ogImage: require("../../assets/img/portfolio/piotrus_pan_top_back.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/tourism-authority-of-thailand/",
      // }}
    >
      <PortfolioHeader show_particles name="piotrus_pan" height="260" />
      <div className="ogrodySwiatel">
        <section
          className="container-fluid portfolio-intro ogrodySwiatel-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>
                  Ogrody Świateł
                  <br />
                  Piotruś Pan
                </h1>
                <ul>
                  <li>Logo</li>
                  <li>Key visual</li>
                  <li>Strona internetowa</li>
                  <li>Materiały social media, Google Ads</li>
                  <li>Strona biletowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  Po sukcesie zeszłorocznej edycji Ogrodów Świateł (Alicja w
                  Krainie Czarów) mieliśmy za zadanie przygotowanie czarującej
                  identyfikacji wizualnej na sezon 2021/2022.
                </p>
                <p>
                  Tym razem wystawa odbywa się w 3 różnych lokalizacjach:
                  Piotruś Pan w Warszawie, Królewna Śnieżka w Krakowie oraz
                  Lampa Aladyna w Chorzowie, na terenie śląskiego wesołego
                  miasteczka ,,Legendia".
                </p>
                <p>
                  Efekt końcowy zrealizowanych przez nas projektów miał
                  zachwycić zarówno rodziców, jak i ich pociechy, aby zachęcić
                  wszystkich do odwiedzenia tegorocznych wystaw całą rodziną.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ogrodySwiatel-section-3">
          <div className="inner">
            <h2>Projekt Logo</h2>
            <p>
              Projekt logo musiał w bezpośredni sposób nawiązywać do tematu
              tegorocznej wystawy. Zielony kolor idealnie wpasowywał się zarówno
              w koncepcję samej wystawy, jak i lokalizacji w której jest ona
              wystawiana, a dobrany kolor oraz rodzaj czcionki pozwolił na
              idealne wyważenie kontrastu, który swą delikatnością nie
              przytłacza odbiorcy i idealnie komponuje się z innymi elementami
              samej identyfikacji wizualnej, jak i materiałami webowymi.
            </p>
          </div>
          <div className="logo-presentation green-gradient">
            <div className="inner">
              <img
                src={require("../../assets/img/portfolio/piotrus_pan_top_logo.svg")}
                alt=""
              />
              <img
                src={require("../../assets/img/portfolio/piotrus_pan_horizontal_logo.svg")}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="ogrodySwiatel-section-4">
          <div className="inner">
            <h2>Key visual</h2>
            <p>
              Key visual został zaprojektowany na bazie ilustracji oraz
              materiałów przygotowanych przez naszych projektantów tak, aby
              nawiązywać zarówno do tematyki samej wystawy, jej lokalizacji, jak
              i grupy docelowej do której jest ona skierowana.
            </p>
          </div>
          <div className="image">
            <img
              src={require("../../assets/img/portfolio/piotrus_pan_main_1.png")}
              alt=""
            />
          </div>
        </section>
        <section className="ogrodySwiatel-section-5">
          <div className="inner">
            <h2>Strona internetowa</h2>
            <p>
              Stworzyliśmy serwis internetowy wystawy, który umożliwia
              zapoznanie się ze wszystkimi najważniejszymi informacjami na jej
              temat, a także pozwala na zakup biletów. Strona została
              zaprojektowana ze szczególnym naciskiem na dostosowanie łatwości
              jej obsługi dla użytkowników korzystających z urządzeń mobilnych.
            </p>
          </div>
          <div className="scroll_wrapper scroll_wrapper--macbook">
            <div className="img_wrapper">
              <img
                src={require("../../assets/img/portfolio/piotrus_pan_full.jpg")}
                className="img-fluid"
                alt="Strona Tourism Authority of Thailand"
                draggable="false"
              />
            </div>
          </div>
        </section>
        <section className="ogrodySwiatel-section-6">
          <div className="images">
            <img
              src={require("../../assets/img/portfolio/piotrus_pan_main_2.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/piotrus_pan_main_3.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/piotrus_pan_main_4.png")}
              alt=""
            />
            <img
              src={require("../../assets/img/portfolio/piotrus_pan_main_5.png")}
              alt=""
            />
          </div>
        </section>
        <section className="ogrodySwiatel-section-7 green-gradient">
          <div className="inner">
            <h2>Materiały social media, Google Ads</h2>
            <p>
              Przygotowaliśmy cover photo oraz zestawy statycznych i
              dynamicznych kreacji banerowych do wykorzystania w kampaniach
              promocyjnych Facebook oraz Google Aads.
            </p>
          </div>
          <img
            className="banner-component"
            src={require("../../assets/img/portfolio/piotrus_pan_main_6.png")}
            alt=""
          />
          <img
            className="image"
            src={require("../../assets/img/portfolio/piotrus_pan_main_7.png")}
            alt=""
          />
        </section>
        <section className="ogrodySwiatel-section-8">
          <div className="inner">
            <h2>Strona biletowa</h2>
            <p>
              Zaprojektowaliśmy dedykowaną stronę biletową wraz ze ścieżką
              zakupową dla ogrodów swiateł we wszystkich trzech lokalizacjach.
            </p>
          </div>
          <img
            className="image_tablet"
            src={require("../../assets/img/portfolio/ogrody_swiatel_tablet.png")}
            alt=""
          />
          <img
            className="image"
            src={require("../../assets/img/portfolio/piotrus_pan_main_8.png")}
            alt=""
          />
          <img
            className="image_bottom"
            src={require("../../assets/img/portfolio/piotrus_pan_main_10.png")}
            alt=""
          />
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default OgrodySwiatelPP;
